.bgVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  background-blend-mode: saturation, normal;
}
