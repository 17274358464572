@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.block {
  @include textBase;
  position: relative;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  margin-top: 15px;
  background-color: $background_object;
  border-radius: 16px;
  box-shadow: $shadow-drops;
  transition: opacity $trans-base, transform $trans-base, visibility $trans-long;
  visibility: hidden;
  transform: translateY(-80px);
  will-change: transform;
  opacity: 0;
  z-index: -5;

  &--shown {
    visibility: visible;
    transform: translateY(0);
    will-change: transform;
    opacity: 1;
    z-index: 99;
  }

  @media (max-width: 576px) {
    position: fixed !important;
    left: 0;
    bottom: 0;
    right: 0;
    height: 64px;
    width: 100%;
    border-radius: 0;
    z-index: 336;
    transform: translateY(80px);
    padding: 8px;

    &--shown {
      visibility: visible;
      transform: translateY(0);
      will-change: transform;
      opacity: 1;
      z-index: 99;
    }
  }
}

.productPreview {
  display: flex;
  gap: 12px;
  align-items: center;
}

.product {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 167px;
  @media (max-width: 576px) {
    min-width: auto;
  }
}

.productName {
  color: $text_variant;

  p {
    overflow: hidden;
    max-width: 167px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
}

.productSum {
  display: flex;
  gap: 8px;

  @media (max-width: 576px) {
    font-size: 14px;
  }

  & span {
    white-space: nowrap;
  }
}

.sumPound {
  color: $text_disabled;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.avatar {
  @include square(76px);
  user-select: none;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 3px solid $background_object;
  border-radius: $radius-small;

  @media (max-width: 576px) {
    @include square(48px);
  }

  & img {
    @include square(100%);
    object-fit: cover;
  }
}

.actions {
  display: flex;
  gap: 12px;
  align-items: center;
  padding-right: 16px;

  @media (max-width: 576px) {
    padding: 0;
  }

  .actionsBox {
    display: flex;
    gap: 12px;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.addGoodsBtn {
  flex-shrink: 0;
  white-space: nowrap;

  @media (max-width: 576px) {
    //max-width: 116px;
  }
}

.closeBtn {
  @include square(24px);
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px;
  color: $icon-secondary-color;
  background: $background_secondary;
  border-radius: 50%;
  transition: color $trans-base;

  @media (max-width: 992px) {
    display: none;
  }

  &:hover {
    color: $icon-primary-color;
  }

  svg {
    @include icn;
    @include square(24px);
  }
}
