@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/typography';

.modalBox {
  @include flexMain(flex, column, center, flex-start);
  gap: 16px;
  align-self: stretch;

  .header {
    @include blockTitle;
    font-weight: 640;
  }

  .content {
    @include flexMain(flex, column, center, flex-start);
    gap: 32px;
    align-self: stretch;
    padding: 8px 0;

    .inputList {
      @include flexMain(flex, column, center, flex-start);
      gap: 16px;
      align-self: stretch;
    }

    .actions {
      @include flexMain(flex, column, center, flex-start);
      gap: 8px;
      align-self: stretch;
    }
  }
}
