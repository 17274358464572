@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/typography';

.container {
  @include flexMain(flex, row, flex-start, center);
  gap: 20px;

  @media (max-width: 576px) {
    @include flexMain(flex, column, flex-start, flex-start);
  }
}

.label {
  @include textPrimary;
  cursor: pointer;
  user-select: none;
  position: relative;
  display: block;
  padding-left: 36px;
}

.input {
  cursor: pointer;
  position: absolute;
  display: none;
  opacity: 0;

  &:checked ~ .checkmark {
    background-color: $background_accent;
  }
}

.input:hover ~ .checkmark {
  border: 1px solid $background_accent;
  transition: all $trans-base;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid $background_secondary;
  border-radius: 50%;
}

.input:checked ~ .checkmark::before {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  width: 9px;
  height: 9px;
  background-color: white;
  border-radius: 50%;
  transition: all $trans-base;
}

.input:checked ~ .checkmark::before,
.input:checked ~ .checkmark::after {
  display: block;
}
