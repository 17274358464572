@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
//@import 'src/assets/styles/sass/commonStyles';
//@import 'src/assets/styles/sass/typography';
@import 'src/assets/styles/sass/modalStyles';

.overlay {
  @extend %modal-overlay;

  &--active {
    @extend %modal-overlay--active;
  }
}

.container-top {
  @include flexMain(flex, column, center, center);
  align-self: stretch;
  background: #ffffff;
  box-shadow: 0 5px 24px 0 rgba(25, 34, 39, 0.1);
  z-index: $z-toast;
  position: fixed;
  flex-shrink: 0;
  transform: translateY(-100%);
  transition: transform $trans-base;
  width: 100%;
  height: 224px;
  left: 0;
  right: 0;
  //scrollbar-width: none; /* Для Firefox */
  //-ms-overflow-style: none; /* Для IE и Edge */

  @media (max-width: 576px) {
    gap: 20px;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-start;
    height: 100%;
  }

  &--active {
    pointer-events: all;
    transform: translateY(0);
    //transition: transform $trans-base; // Удалено отсюда
  }
}
.closeBox {
  @extend %closeBox;

  &--active {
    @extend %closeBox--active;
  }
}
