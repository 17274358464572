@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

@media (max-width: 1100px) {
  .isFloat {
    bottom: 110px;
  }
}

@media (max-width: $media-phone) {
  .isFloat {
    bottom: 80px;
  }
}

.block {
  position: fixed;
  //position: relative;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 404px;
  padding: 16px 12px 16px 16px;
  gap: 8px;
  vertical-align: middle;
  border-radius: $radius-small;
  transition: transform $trans-blocks, opacity $trans-long, visibility $trans-long;
  z-index: 99999;
  //text-transform: lowercase;

  &--hidden {
    pointer-events: none;
    transform: translateY(210%);
    visibility: hidden;
    opacity: 0;
  }

  // types
  &--error {
    background: #fbedee;
    left: 26px;
    right: auto;
    transition: all $trans-base;

    @media (max-width: $media-phone) {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 22px 12px 22px 16px;
      bottom: 0 !important;
    }
  }

  &--success {
    background: #f1faf0;
    right: 26px;
    left: auto;
    transition: all $trans-base;

    @media (max-width: $media-phone) {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 22px 12px 22px 16px;
      bottom: 0 !important;
    }
  }

  &--cookie {
    background: $background_accent;
    left: 26px;
    right: auto;
    height: 48px;
    padding: 0 12px 0 16px;
    gap: 16px;
    transition: all $trans-base;
    z-index: $z-overlay;

    @media (max-width: $media-phone) {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 22px 12px 22px 16px;
    }
  }

  &--info {
    @include textBase;
    display: block;
    right: 32px;
    padding: 20px;
    background: $background_object;
    box-shadow: 0 5px 24px 0 rgba(25, 34, 39, 0.1);
    outline: 1px solid $border_primary;
    min-width: 335px;
    transition: all $trans-base;

    @media (max-width: 769px) {
      right: 0;
      margin: 0;
      bottom: 20px;
    }

    @media (max-width: $media-phone) {
      position: fixed;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      padding: 22px 16px 22px 16px;
      animation-duration: 0.3s;
      bottom: 0 !important;
      border-radius: 0;
    }

    .divider {
      width: 2px;
      height: 21px;
      background: $border_secondary;
    }

    .returnBtn {
      @include btn-reset;
      @include textBaseAccent;
    }

    .title {
      @include textBase;
    }

    &:hover {
      outline: 2px solid $border_primary_accent;
      transition: outline $trans-base;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}

.typeIcn {
  @include icn;
  margin-right: 12px;

  &--error {
    color: $system_error;
  }

  &--success {
    color: $system_good;
  }
}

.title {
  @include textCaption;
  cursor: default;
  color: $text_variant;

  &--cookie {
    color: $text_primary_invert;

    &-last {
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

.close {
  @include square(24px);
  @include btn-reset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $text_variant_hover;
  transition: color $trans-base;
  cursor: pointer;

  &--cookie {
    color: $text_primary_invert;
  }

  &:hover {
    color: $text_variant;
  }
}

.closeIcn {
  @include icn;
  @include square(24px);

  &--cookie {
    color: $text_primary_invert;
  }
}
