@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.spin {
  @include square(28px);
  user-select: none;
  position: relative;
  display: inline-block;
}

.circular {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform-origin: center center;
  width: 100%;
  height: 100%;
  margin: auto;
  animation: rotate 2s linear infinite;
}

.path {
  stroke: currentColor;
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.2s ease-in-out infinite;
}

@keyframes rotate{
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash{
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}

