@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.wrapper {
  pointer-events: none;

  position: fixed;
  z-index: $z-header;
  top: 0;
  left: 0;

  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  width: 100%;
  height: 100%;

  visibility: hidden;
  opacity: 0;
  background: rgba($background_black, 0.3);

  transition: opacity $trans-base, visibility $trans-base;

  &.is-open {
    pointer-events: all;
    z-index: 9999;
    visibility: visible;
    opacity: 1;
  }
}

.bg {
  @include absolute-layer;
}

.block {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  transform: translateX(100%);

  overflow: auto;

  width: 100%;
  height: 100%;

  background: $background_primary;
  box-shadow: $shadow-drops;

  transition: transform $trans-sides;

  .is-open & {
    transform: translateX(0);
  }
}

.header {
  @include flexMain(flex, row, space-between, center);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px 0;
  //padding: 16px 20px;
  //background: $background_primary;

  @media (max-width: 576px) {
    padding: 16px 20px;
  }
}

.close {
  @include btn-reset;
  @include square(32px);

  color: $text_variant;

  transition: color $trans-base;

  &:hover {
    color: $text_variant_hover;
  }
}

.closeIcn {
  @include icn;

  @include square(32px);
}

.logo {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none !important;
  color: $text_variant;
  transition: opacity $trans-base, color $trans-base;

  .block--inverted & {
    color: $text_primary_invert;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.5;
  }
}

.logoImg {
  width: 105.749px;
  height: 24px;
  flex-shrink: 0;

  @media (max-width: 576px) {
    width: 80px;
    height: 18px;
    flex-shrink: 0;
  }
}

.body {
  max-width: 228px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 72px;
  margin: 0 auto 40px;

  @media (max-width: 576px) {
    max-width: 100%;
    margin: 0 20px 20px;
  }

  .burgerMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: auto;
    @include burgerText;
    min-width: 228px;

    .menuEl {
      color: $text_primary;
    }
  }

  .btnBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .modalBtn {
      width: 100%;
      white-space: nowrap;
    }
  }
}
