@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/typography';

.wrapper {
  display: flex;
  width: 100%;
  //overflow: hidden;
  //overflow: auto;
  //scrollbar-width: none; /* Для Firefox */
  ////max-width: 90vw;
  //-ms-overflow-style: none; /* Для IE и Edge */
  //
  //&::-webkit-scrollbar {
  //  width: 0; /* Для Chrome, Safari и Opera */
  //  height: 0;
  //}

  //@media (max-width: 576px) {
  //  overflow: auto;
  //  scrollbar-width: none; /* Для Firefox */
  //  //max-width: 90vw;
  //  width: 100%;
  //  -ms-overflow-style: none; /* Для IE и Edge */
  //
  //  &::-webkit-scrollbar {
  //    width: 0; /* Для Chrome, Safari и Opera */
  //    height: 0;
  //  }
  //}
  scrollbar-width: none; /* Для Firefox */
  overflow: hidden;
  overflow-y: auto;
}

.loginForm {
  min-width: 100%;
  background-color: $background_primary;
}

.passwordRecoveryForm {
  min-width: 100%;
  background-color: $background_primary;
}

.registrationForm {
  min-width: 100%;
  background-color: $background_primary;
  //scrollbar-width: none; /* Для Firefox */
  //overflow: hidden;
  //overflow-y: auto;
}

.modalBox {
  @include flexMain(flex, column, center, flex-start);
  gap: 24px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90%;

    @include blockTitle;
    text-transform: lowercase;

    span {
      display: block;
      @include textBase;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .content {
    @extend %rootModalContent;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 12px;
  }

  .submitBtn {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .privacyPolicy {
      @include flexMain(flex, row, center, center);
      gap: 16px;
      margin-top: 12px;

      .agreementText {
        @include textCaption;
        color: $text_disabled;
        //text-align: center;

        @media (max-width: 576px) {
          @include textSmall;
        }

        a {
          color: $text_disabled;
          transition: all $trans-base;

          &:hover {
            color: $text_variant_hover;
          }
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
