@import 'src/assets/styles/sass/variables';

@import 'src/assets/styles/sass/mixins';

@import 'src/assets/styles/sass/typography';

.block {
  user-select: none;
  position: sticky;
  display: flex;
  bottom: 28px;

  overflow: hidden;

  width: 500px;
  height: 72px;
  padding: 16px 24px 16px 24px;
  margin: 0 auto;

  visibility: visible;
  background: $background_primary;
  border: 2px solid transparent;
  border-radius: $radius-base;
  box-shadow: $shadow-cart;

  transition: border $trans-base, transform $trans-blocks, opacity $trans-long,
    visibility $trans-long;

  cursor: pointer;
  z-index: 10;

  &:hover {
    border-color: $border_primary_accent;
  }

  &--hidden {
    pointer-events: none;
    transform: translate(0, 110%) !important;
    visibility: hidden;
    opacity: 0;
  }

  @media (max-width: 576px) {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    width: 100%;
    margin-top: auto;
    height: 64px;
    left: 0;
    right: 0;
    transform: translate(0);
    padding: 12px 12px 12px 16px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.title {
  @include blockTitle;

  pointer-events: none;
  white-space: nowrap;
  text-transform: lowercase;

  @media (max-width: 576px) {
    font-size: 16px;
  }
}

.goods {
  display: flex;
}

.listWrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  width: 200px;

  @media (max-width: 576px) {
    width: 160px;
  }
}

.listItemWrapper {
  height: 40px;

  @media (max-width: 576px) {
    height: 32px;
  }
}

.listItem,
.numbers,
.numbersEl {
  display: inline-flex;
  position: relative;
  @include square(40px);
  user-select: none;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: $radius-small;

  @media (max-width: 576px) {
    @include square(32px);
  }
}

.listItem {
  border: 1px solid $border_disabled;
  transition: all 1s;
  margin-right: -3px;

  @media (max-width: 576px) {
    @include square(32px);
  }

  img {
    @include square(100%);
    object-fit: cover;
    border-radius: 12px;
  }
}

.numbers,
.numbersList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.numbers {
  display: none;
  position: relative;
  @include subTitle;
  color: $text_primary_invert;
  text-transform: lowercase;
  background: $text_variant;

  @media (max-width: 576px) {
    font-size: 16px;
  }

  &--active {
    display: inline-flex;
    transform: translateX(-24px);

    @media (max-width: 576px) {
      transform: translateX(0px);
    }
  }
}

.numbersList {
  width: 100%;
  transition: all 0.5s ease;
}

.numbersEl {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform $trans-base;
}

.numbers-animate {
  animation: slideUpAnimation $trans-base;
}

@keyframes slideUpAnimation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
