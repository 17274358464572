@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/typography';


.container {
  @include flexMain(flex, column, center, center);
  width: 100%;
  height: 100%;
  gap: 32px;
  padding-top: 20px;

  @media (max-width: 769px) {
    gap: 24px;
    padding-top: 0;
  }

  .emptyBox {
    @include flexMain(flex, column, center, center);
    align-self: stretch;
    gap: 12px;
    width: 100%;
    height: 100%;
    min-height: 219px;
    border: 2px dashed $border_secondary;
    border-radius: 8px;
    padding: 32px;
    text-align: center;

    & span {
      @include subTitle;
      color: $text_disabled;
      text-transform: lowercase;
    }
  }

  .linkBox {
    @include flexMain(flex, row, center, center);
    gap: 16px;

    @media (max-width: 376px) {
      display: none;
    }

    .button {
      border: 1px solid $border_primary;
    }
  }

}