@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.block {
  pointer-events: none;

  position: absolute;
  z-index: $z-onboarding;
  top: $controll-large + 16px;
  left: 50%;
  transform: translate(-50%, 40%);

  width: 380px;

  visibility: visible;
  visibility: hidden;
  opacity: 0;
  background: $background_secondary_hover;
  backdrop-filter: blur(8px);
  border-radius: $radius-blocks;
  box-shadow: $shadow-drops;

  transition: transform $trans-base, opacity $trans-fast, visibility $trans-fast;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-width: 0;
    border-top-color: rgba(20, 24, 36, 0.88);
    top: -6px;
    left: 50%;
    rotate: 180deg;
  }

  @media (max-width: 576px) {
    width: 335px;
  }

  &--shown {
    pointer-events: all;

    transform: translate(-50%, 0);

    visibility: visible;
    opacity: 1;
  }
}

.close {
  @include btn-reset;
  @include square(24px);
  position: absolute;
  top: 16px;
  right: 16px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: $text_primary_invert;

  background: rgba($background_primary, 0.14);
  border-radius: 50%;

  transition: color $trans-base, background $trans-base;

  &:hover {
    color: $background_secondary_active;

    background: $background_primary;
  }
}

.closeIcn {
  @include square(24px);
  @include icn;
}

.body {
  padding: 16px;

  color: $text_primary_invert;
}

.title {
  @include textBaseAccent;
  cursor: default;

  margin-bottom: 8px;
}

.text {
  @include textCaption;
  cursor: default;
  margin-bottom: 12px;
  color: $text_white_transparent;
  @media (max-width: 576px) {
    font-size: 13px;
  }
}

.closeBtn {
  max-width: 100px;
}
