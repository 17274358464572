$font-base-size: 14px;
$font-base-lh: 1.4;
$font-family: 'Roboto Flex', 'Arial', 'Helvetica CY', 'Nimbus Sans L', sans-serif;

$form-line-height: 1.25;

@-ms-viewport {
  width: device-width;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
em,
strike,
strong,
s,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
main {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  outline: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
main,
menu,
picture {
  display: block;
}

*,
*::before,
*::after {
  box-shadow: none;
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // =10px
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar;
  box-sizing: border-box;
}

body {
  font: #{$font-base-size}/#{$font-base-lh} $font-family;
  text-align: left;
  font-weight: 400;
  font-feature-settings: 'liga' 0;
  min-height: 100vh;
  min-width: 320px;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Links
a {
  background: transparent;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration-skip: spaces;
  text-decoration: none;
  text-decoration-skip: objects;
  transition-duration: 0.2s;
}

a:focus {
  outline: thin dotted;
}

a:hover {
}

a:hover,
a:active {
  outline: 0;
}

a,
area,
button,
input:not([type='range']),
label,
select,
textarea,
.btn {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

// content
menu,
ol,
ul {
  list-style: none;
}

img {
  border-style: none;
  user-select: none;
  vertical-align: middle;
  max-width: 100%;
}

b,
strong {
  font-weight: 700;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

// tables
table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  empty-cells: show;
}

td,
td img {
  vertical-align: top;
}

th {
  text-align: inherit;
}

// Controls
input,
textarea,
select,
optgroup,
button {
  color: inherit;
  font-family: inherit;
  line-height: $form-line-height;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  border-radius: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='checkbox'] {
  vertical-align: baseline;
}

input[type='radio'] {
  vertical-align: text-bottom;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button,
label,
input[type='button'],
input[type='submit'],
input[type='reset'] {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
  cursor: pointer;
}

input[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
  border-radius: 0;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

::-ms-clear,
::-ms-reveal,
input::-ms-clear,
input::-ms-reveal {
  display: none !important;
}

// для скрытия иконки генерации пароля в сафари и где-то там еще
input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input[type='email']::-webkit-contacts-auto-fill-button,
input[type='email']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
  position: absolute;
}

input:focus::-webkit-contacts-auto-fill-button,
input:focus::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input[autocomplete='off']::-webkit-contacts-auto-fill-button,
input[autocomplete='off']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
