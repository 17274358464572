@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
}
.card {
  @include square(100px);
  flex: 0 0 auto;
  color: $icon-primary-color;
  border-radius: $radius-blocks;
  background: $background_primary;
  box-shadow: $shadow-block;
  transition: box-shadow $trans-base, background $trans-base, backdrop-filter $trans-base;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: rgba($background_primary, 0.40);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
  }
}
