@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/typography';

.block {
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 24px;

  .checkbox {
    display: none;

    &:checked + .label {
      border: 7px solid $border_primary_accent;
      transition: border-width $trans-base;
    }

    &:disabled + .label {
      border: 7px solid $text_disabled;
    }
  }

  .label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    @include square(24px);
    border: 2px solid $border_primary;
    border-radius: 50%;
  }
}
