@import 'src/assets/styles/sass/variables';
@import 'src/assets/styles/sass/mixins';
@import 'src/assets/styles/sass/commonStyles';
@import 'src/assets/styles/sass/typography';
@import 'src/assets/styles/sass/modalStyles';

.content {
  @include flexMain(flex, column, space-between, center);
  gap: 16px;
  min-height: 576px;
  width: 470px;
  box-sizing: border-box;

  @media (max-width: 576px) {
    width: 80vw;
    min-height: 100%;
  }

  @media (max-height: 376px) {
    min-height: 80vh;
    min-width: 50vw;
    gap: 0;
  }
}

.imgBox {
  @include flexMain(flex, column, center, center);
  width: 470px;
  height: 470px;
  //width: 100%;
  //height: 100%;
  //height: 80vh; // 80% от высоты области просмотра
  //width: 80vw; // 80% от ширины области просмотра
  position: relative;
  padding: 32px 32px 0 32px;

  @media (max-width: 576px) {
    width: fit-content;
    height: fit-content;
    padding: 16px 16px 0 16px;
  }
  @media (max-height: 376px) {
    width: 100%;
    height: 100%;
    padding: 16px 16px 0 16px;
    //max-width: 100%;
    //max-height: 100%;
    //aspect-ratio: 16/9;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 576px) {
      //aspect-ratio: 16/9;
    }

    @media (max-height: 376px) {
      aspect-ratio: 16/9;
    }
  }
}

.description {
  @include flexMain(flex, column, center, flex-start);
  gap: 8px;
  padding: 0 32px 32px 32px;
  align-self: stretch;

  @media (max-width: 576px) {
    padding: 0 16px 16px 16px;
  }

  @media (max-height: 376px) {
    padding: 0 16px 16px 16px;
  }
}

.title {
  @include blockTitle;
  overflow: hidden;
  color: $text_primary;
  text-overflow: ellipsis;

  @media (max-width: 576px) {
    @include subTitle;
  }

  @media (max-height: 376px) {
    @include subTitle;
  }
}

.text {
  @include subTitle;
  overflow: hidden;
  color: $text_tertiary;
  text-overflow: ellipsis;

  @media (max-width: 576px) {
    @include textBase;
  }

  @media (max-height: 376px) {
    @include textBase;
  }
}
